import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import CustomButton from 'components/UI/CustomButton';
import FileUpload from 'components/UI/FileUpload';
import Modal from 'components/UI/Modal';
import { Col, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BadgeType from '../UI/Table/BadgeType';
import KybInfo from './KybInfo';
import {
  approveDisapproveKYBRequests,
  getSingleComplience,
  getSingleKybRequest,
} from 'redux/actions/KybRequestsAction';
import CustomInput from 'components/UI/CustomInput';
import { getDocmentTypes } from 'utils/helper';
import FileView from 'components/UI/FileView';
import Documents from 'components/Documents';
import CustomSelect from 'components/UI/CustomSelect';
import OnboardingDetails from 'pages/KYB/OnboardingDetails';

const KybModal = ({ selectKyb, setSelectKyb }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [Kybrequest, setKybrequest] = useState([]);
  const [editCompany, setEditCompany] = useState(false);

  const {
    getSingleKybRequest: { data: kybData, loading },
    getSingleComplience: { data: complienceData, loading: loadingComplience },
    approveDisapproveKYBRequest: { success: approved, error: rejected },
  } = useSelector(({ Kybrequest }) => Kybrequest);

  useEffect(() => {
    if (selectKyb) {
      dispatch(getSingleKybRequest(selectKyb.kybData.code));
    }
  }, [selectKyb]);

  useEffect(() => {
    if (approved) {
      handleClose();
    }
  }, [approved, rejected]);

  const handleClose = () => {
    setSelectKyb(null);
  };

  const approveDisapproveKYBRequest = (status) => {
    const payload = {
      decision: status,
      company: kybData.code,
    };
    dispatch(approveDisapproveKYBRequests(payload));
  };

  // const handleClick = () => {
  //   history.push(`/budgets/${selectKyb?.budget?.code}/overview`);
  // };
  const edit = () => {
    setEditCompany(!editCompany);
  };

  if (!selectKyb) return <div />;
  return (
    <Modal show={selectKyb} onClose={handleClose}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body" style={{ color: '#ffffff' }}>
          <div className="information-wrapper">
            <div className="flex">
              {/* <Row>
                <span className="edit" onClick={edit}>
                  Edit
                </span>
              </Row> */}
              <BadgeType value={selectKyb.status} />
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <h2 className="card-title">{selectKyb.businessname}</h2>
            </div>
            <span style={{ color: '#BFC6B9', fontSize: '14px', fontWeight: '400' }}>
              {selectKyb?.creationdate}
            </span>
            <hr />
            {editCompany ? (
              <OnboardingDetails />
            ) : (
              <>
                <KybInfo title="Business Name" value={selectKyb?.businessname} />
                <KybInfo title="Business Code" value={selectKyb?.kybData?.code} />
                <KybInfo
                  title="Business Address"
                  value={selectKyb?.kybData?.address?.street}
                />
                <KybInfo title="Number of employees" value={selectKyb?.kybData?.size} />
                <KybInfo
                  title="Description"
                  value={kybData?.description ? kybData?.description : 'N/A'}
                />
                <KybInfo title="Website" value={selectKyb?.kybData?.website} />
                <KybInfo
                  title="Email address"
                  value={selectKyb?.kybData?.contact_email}
                />
                <KybInfo
                  title="Phone number"
                  value={`+${selectKyb?.kybData?.contact_phone}`}
                />
                <KybInfo title="Business Type" value={selectKyb?.kybData?.businessType} />
                <Documents documents={kybData?.documents} />
              </>
            )}
            {!editCompany && (
              <>
                <div style={{ marginTop: '20px' }}>
                  <CustomButton
                    fullWidth
                    className="mt-3"
                    onClick={() => approveDisapproveKYBRequest('ACCEPTED')}
                  >
                    Approve request
                  </CustomButton>
                </div>
                <div className="ms-auto">
                  <CustomButton
                    withoutBg
                    fullWidth
                    className="mt-3"
                    onClick={() => approveDisapproveKYBRequest('REJECTED')}
                  >
                    Decline request
                  </CustomButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KybModal;
