import React, { useEffect, useState } from 'react';
import './style.less';
import TopBar from 'components/TopBar';
import NoData from 'components/NoData';
import Table from 'components/Table';
import { columns } from 'utils/mockData';
import { Col, Container, Row } from 'react-bootstrap';
import TransactionModal from 'components/TransactionModal';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/UI/Loading';
import { getTransaction } from 'redux/actions/TransactionsAction';
import { buildTransactionTableData } from '../../utils/helper';
import {
  CardType,
  CurrencyType, PaymentMethod, TransactionStatus,
} from 'components/FilterModal/FilterHelper';
import { getBudgets } from 'redux/actions/BudgetsAction';

const Transactions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectTransaction, setSelectTransaction] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search) || {};
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatus,
    ...PaymentMethod,
    //...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const {
    getTransaction: { data: { meta = {} } = {}, data = [], loading },
  } = useSelector(({ transaction }) => transaction);
  const { page, total, hasMore, perPage } = meta;
  const { transactions = [] } = data;
  const nextPage = page + 1;

  const { user = {} } = useSelector(({ auth }) => auth);
  const role = user?.data?.user.role;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
  };

  useEffect(() => {
    if (transactions.length > 0) {

      setTableData(buildTransactionTableData(transactions));

      const allTransactions = transactions.map(({ payer, }) => {
        return {
          value: payer ? payer.code : null,
          label: payer ? `${payer.firstName} ${payer.lastName}` : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );
      setFilterData([
        ...filterData,
        { title: 'Budget', list: data },
        { title: 'Owner/holder', list: uniqueArray },
      ]);
    }
  }, [transactions]);

  useEffect(() => {
    dispatch(getTransaction());
  }, []);

  const handleNextPage = () => {
    if (!hasMore) return;
    dispatch(getTransaction({ page: page + 1, ...query }));
  }
 
  const handlePreviousPage = () => {
    if (!hasMore) return;
    dispatch(getTransaction({ page: page - 1, ...query }));
  }
 
  const handleFilter = (query) => {
    dispatch(getTransaction(query));
  };
  

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="transactions-wrapper">
      <TopBar
        headerText={'Transactions'}
        number={total}
        addOnClick={toggleHandler}
        addIcon={false}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
      />
      {!tableData.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No transactions yet - We need to make some sales"
            withButton={false}
          />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12}>
                <Table
                  columns={columns}
                  data={tableData}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                  nextPage={handleNextPage}
                  previousPage={handlePreviousPage}
                />
              </Col>
            </Row>
          </Container>

          <TransactionModal
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </>
      )}
    </div>
  );
};

export default Transactions;
