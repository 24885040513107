import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FilterList from './FilterList';
import AccordionCard from '../UI/AccordionCard';
import CustomButton from '../UI/CustomButton';
import './styles.less';
import CustomDate from '../UI/CustomDate';

const FilterModal = ({
  filterData = [],
  inputPlaceholder = 'Search ',
  handleFilterSelect,
  handleFilterApply,
  handleToggleFilterPopover,
  withDate,
  withOutSearch=false,
}) => {
  const [activeKey, setActiveKey] = useState('0');
  const [searchVal, setSearchVal] = useState('');
  const [date, setDate] = useState({
    from: '',
    to: '',
  });
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const handleChange = (value, checked, type, title) => {
    if (type === 'Checkbox') {
      const updateData = filterData.map((list) => {
        if (list.title === title) {
          const fromIndex = list.list.map((object) => object.value).indexOf(value);
          const toIndex = 0;
          const element = list.list.splice(fromIndex, 1)[0];
          list.list.splice(toIndex, 0, element);
        }

        if (list.type === 'slider') return { ...list };
        return {
          ...list,
          list: list.list?.map((list) => {
            if (list.value === value) {
              return {
                ...list,
                isSelected: checked,
              };
            } else {
              return { ...list };
            }
          }),
        };
      });
      //
      handleFilterSelect(updateData, { value, checked });
    }
    if (type === 'Slider') {
      const setFilter = filterData.map((list) => {
        if (list.type === 'slider') {
          return {
            ...list,
            value: value,
          };
        }
        return { ...list };
      });
      handleFilterSelect(setFilter, {});
    }
  };

  const handleApply = () => {
    const query = {};
    if (searchVal) {
      query.search = searchVal;
    }
    if (withDate) {
      if (date.from) query.from = date.from;
      if (date.to) query.to = date.to;
    }

    handleFilterApply(query);
    handleToggleFilterPopover();
  };

  const handleDateChange = ({ target: { value, name } }) => {
    setDate({ ...date, [name]: value });
  };
  return (
    <div className="actions-dialog pb-3 set-height-filter">
      <h3 className="filter-title">Filter</h3>
      {!withOutSearch && (
        <div className="search-wrapper pb-2">
          <Input
            placeholder={inputPlaceholder}
            prefix={<SearchOutlined className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      )}
      <Accordion defaultActiveKey="0" activeKey={activeKey}>
        {withDate && (
          <AccordionCard
            eventKey={filterData.length.toString()}
            activeKey={activeKey}
            title={'Creation date'}
            handleClick={handleSelect}
            isFilter
            contextClass="ps-2 pe-4"
            wrapperClass="pb-1 pt-2"
          >
            <div className="filter-action-list pb-0">
              <Row className="mb-3">
                <Col md={6}>
                  <CustomDate label="From" name="from" onChange={handleDateChange} />
                </Col>
                <Col md={6}>
                  <CustomDate label="To" name="to" onChange={handleDateChange} />
                </Col>
              </Row>
            </div>
          </AccordionCard>
        )}
      </Accordion>

      <div className="px-3 mt-2">
        <CustomButton className="apply-btn" onClick={handleApply}>
          Apply
        </CustomButton>
      </div>
    </div>
  );
};
export default FilterModal;
