import { CloseOutlined } from '@ant-design/icons';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSingleCompany } from 'redux/actions/CompaniesAction';
import { getSingleComplience } from 'redux/actions/KybRequestsAction';
import BadgeType from '../UI/Table/BadgeType';
import KybInfo from './KybInfo';
import CompanyOnboardingDetails from './OnboardingDetails';
import { RESET_BLOCK_KYBREQUESTS } from 'redux/reducers/KybRequestsReducer';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';

const CompanyModal = ({ selectCompany, setSelectCompany }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editCompany, setEditCompany] = useState(false);

  const {
    getSingleCompany: { data: companyData, loading: loadingCompany },
    // updateCompany: { loading, success },
  } = useSelector(({ companies }) => companies);

  const {
    getSingleComplience: { data: complienceData, loading: loadingComplience },
    updateKybRequest: { loading, success },
  } = useSelector(({ Kybrequest }) => Kybrequest);
  const statusVerifying = selectCompany?.companiesData?.status === 'verifying';

  const getData = statusVerifying ? complienceData : companyData;

  useEffect(() => {
    if (selectCompany || success) {
      if (statusVerifying) {
        dispatch(getSingleComplience(selectCompany.companiesData.code));
      } else {
        dispatch(getSingleCompany(selectCompany.companiesData.code));
      }
    }
  }, [selectCompany, success]);

  const handleClose = () => {
    setSelectCompany(null);
    setEditCompany(false);
    dispatch({ type: RESET_BLOCK_KYBREQUESTS, blockType: 'getSingleComplience' });
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'getSingleCompany' });
  };

  const edit = () => {
    setEditCompany(!editCompany);
  };

  useEffect(() => {
    if (success) {
      setEditCompany(false);
    }
  }, [success]);

  // const [companyEditData, setCompanyData] = useState({
  //   email: '',
  //   size: '',
  //   status: '',
  // });

  // const handleSubmitEdit = () => {
  //   const payload = {
  //     code: selectCompany.companiesData.code,
  //     status: companyEditData.status.value,
  //     size: companyEditData.size.value,
  //     contactEmail: companyEditData.email.value,
  //   };
  //   dispatch(updateCompany(payload));
  // };

  const handleCancel = () => {
    setEditCompany(false);
  };

  if (!selectCompany) return <div />;

  return (
    <Modal show={selectCompany} onClose={handleClose}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body" style={{ color: '#ffffff' }}>
          {loadingComplience || loadingCompany ? (
            <Loading isPage color="#D28B28" />
          ) : (
            <>
              <div className="information-wrapper">
                <Row>
                  <span className="edit" onClick={edit}>
                    Edit
                  </span>
                </Row>
                <BadgeType value={selectCompany.status} />
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="card-title">{selectCompany?.name}</h2>
                </div>
                <span style={{ color: '#BFC6B9', fontSize: '14px', fontWeight: '400' }}>
                  {selectCompany?.creationdate}
                </span>
                <hr />
                {editCompany ? (
                  <CompanyOnboardingDetails
                    data={getData}
                    statusVerifying={statusVerifying}
                    selectCompany={selectCompany}
                    handleCancel={handleCancel}
                  />
                ) : (
                  <>
                    <KybInfo title="Size" value={selectCompany?.companiesData?.size} />
                    <KybInfo title="Code" value={selectCompany?.companiesData?.code} />
                    <KybInfo title="Business Website" value={selectCompany?.website} />
                    <KybInfo
                      title="Business Description"
                      value={companyData?.description ?? complienceData?.description}
                    />
                    <KybInfo
                      title="Industry"
                      value={
                        companyData?.industry?.name ?? complienceData?.industry?.name
                      }
                    />

                    <KybInfo
                      title="Email address"
                      value={
                        companyData?.contact_email ??
                        complienceData?.contact_email ??
                        'N/A'
                      }
                    />
                    <KybInfo
                      title="Phone number"
                      value={
                        companyData?.contact_phone ?? complienceData?.phoneNumber ?? 'N/A'
                      }
                    />
                    <KybInfo
                      title="Business Type"
                      value={
                        companyData?.businessType ?? complienceData?.businessType ?? 'N/A'
                      }
                    />

                    <div className="mt-4 text-white fw-bold">Address</div>
                    <KybInfo
                      title="Country"
                      value={
                        companyData?.address?.country ??
                        complienceData?.address?.country ??
                        'N/A'
                      }
                    />
                    <KybInfo
                      title="City"
                      value={
                        companyData?.address?.city ??
                        complienceData?.address?.city ??
                        'N/A'
                      }
                    />
                    <KybInfo
                      title="State"
                      value={
                        companyData?.address?.state ??
                        complienceData?.address?.state ??
                        'N/A'
                      }
                    />
                    <KybInfo
                      title="Address"
                      value={
                        companyData?.address?.street ??
                        complienceData?.address?.street ??
                        'N/A'
                      }
                    />
                    {complienceData?.registeredAddress && (
                      <>
                        <div className="mt-4 text-white fw-bold">Registered Address</div>
                        <KybInfo
                          title="Country"
                          value={complienceData?.registeredAddress?.country ?? 'N/A'}
                        />
                        <KybInfo
                          title="City"
                          value={complienceData?.registeredAddress?.city ?? 'N/A'}
                        />
                        <KybInfo
                          title="State"
                          value={complienceData?.registeredAddress?.state ?? 'N/A'}
                        />
                        <KybInfo
                          title="Address"
                          value={complienceData?.registeredAddress?.street ?? 'N/A'}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CompanyModal;
