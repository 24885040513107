import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { updateKybRequest } from 'redux/actions/KybRequestsAction';
import {
  businessTypes,
  getCountry,
  getInternationalFormat,
  industry,
  states,
} from '../../utils/helper';

const CompanyOnboardingDetails = ({
  data,
  statusVerifying,
  selectCompany,
  handleCancel,
}) => {
  const dispatch = useDispatch();

  const [companyEditData, setCompanyData] = useState({
    email: '',
    size: '',
    status: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setCompanyData({ ...companyEditData, [name]: value });
  };

  const handleSubmit = () => {
    const payloadData = {
      code: selectCompany.companiesData.code,
      name: companyEditData?.name,
      website: companyEditData?.website,
      contactEmail: companyEditData?.contactEmail,
      description: companyEditData?.description,
      dateOfRegistration: companyEditData?.dateOfRegistration,
      size: companyEditData?.size?.value,
      industry: companyEditData?.industry?.value,
      businessType: companyEditData?.businessType?.value,
      address: {
        street: companyEditData?.address?.street,
        city: companyEditData?.address?.city,
        state: companyEditData?.address?.state?.label,
        country: companyEditData?.address?.country?.value,
        postalCode: companyEditData?.address?.postalCode,
      },
      registeredAddress: {
        street: companyEditData?.registeredAddress?.street,
        city: companyEditData?.registeredAddress?.city,
        state: companyEditData?.registeredAddress?.state?.label,
        country: companyEditData?.registeredAddress?.country?.value,
        postalCode: companyEditData?.registeredAddress?.postalCode,
      },
    };
    dispatch(updateKybRequest(payloadData));
  };

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      const {
        name,
        website,
        contactEmail,
        size,
        description,
        dateOfRegistration,
        address,
        firstName,
        lastName,
        email,
        bvn,
        registeredAddress,
        phoneNumber = {},
      } = data;

      setCompanyData({
        ...companyEditData,
        name: name || '',
        website: website || '',
        contactEmail: contactEmail || '',
        size: size || '',
        bvn: bvn || '',
        description: description || '',
        industry: industry.find((item) => item?.value === data?.industry?.code) || '',
        businessType:
          businessTypes.find((item) => item?.value === data?.businessType) || '',
        dateOfRegistration: dateOfRegistration || '',
        firstName: firstName || '',
        lastName: lastName || '',
        localFormat: phoneNumber?.localFormat || '',
        countryCode: phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(phoneNumber?.countryCode, phoneNumber?.localFormat) ||
          '',
        email: email || '',
        address: {
          street: address?.street || '',
          city: address?.city || '',
          state: states.find((item) => item?.label === address?.state) || '',
          country:
            getCountry.find(
              (item) =>
                item?.iso_code === address?.country ||
                item?.iso_code === address?.countryIso,
            ) || '',
        },
        registeredAddress: {
          street: registeredAddress?.street || '',
          city: registeredAddress?.city || '',
          state: states.find((item) => item?.label === registeredAddress?.state) || '',
          country:
            getCountry.find(
              (item) =>
                item?.iso_code === registeredAddress?.country ||
                item?.iso_code === registeredAddress?.countryIso,
            ) || '',
        },
      });
    }
  }, [data]);
  console.log(companyEditData);
  return (
    <div className="Profile-wrapper pt-1 pb-5">
      <Container>
        <Row className="mb-1">
          <Col md={12} className="form-normal">
            <div className="text-white fw-bold">Personal Information</div>
            <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="Name"
                placeholder="Enter name"
                name="name"
                value={companyEditData.name}
                onChange={handleChange}
                md={6}
              />

              <CustomInput
                type="text"
                label="Website"
                placeholder="Enter website"
                name="website"
                value={companyEditData.website}
                onChange={handleChange}
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="Contact email"
                placeholder="Enter contact email"
                name="contactEmail"
                value={companyEditData.contactEmail}
                onChange={handleChange}
                md={6}
              />

              <CustomSelect
                placeholder="Select industry"
                type="text"
                name="industry"
                label="Industry"
                md={6}
                options={industry}
                onChange={(val) => setCompanyData({ ...companyEditData, industry: val })}
                value={companyEditData.industry}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="date"
                label="Date of registration"
                placeholder="date of registration"
                name="dateOfRegistration"
                value={companyEditData.dateOfRegistration}
                onChange={handleChange}
                md={6}
              />

              <CustomSelect
                placeholder="Select business type"
                type="text"
                name="businessType"
                label="Business type"
                md={6}
                onChange={(val) =>
                  setCompanyData({ ...companyEditData, businessType: val })
                }
                value={companyEditData.businessType}
                options={businessTypes}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomTextarea
                type="text"
                label="Description"
                placeholder="Enter description"
                name="description"
                value={companyEditData.description}
                onChange={handleChange}
                rowSize={5}
                md={6}
              />
            </Row>

            <div className="mt-5 text-white fw-bold">Director Info</div>
            {/* <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name="firstName"
                value={companyEditData.firstName}
                onChange={handleChange}
                md={6}
              />

              <CustomInput
                type="text"
                label="Last name"
                placeholder="Enter last name"
                name="lastName"
                value={companyEditData.lastName}
                onChange={handleChange}
                md={6}
              />
            </Row> */}

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="BVN"
                placeholder="Enter BVN"
                name="bvn"
                value={companyEditData.bvn}
                onChange={handleChange}
                md={6}
              />

              {/* <CustomInput
                type="text"
                label="Ownership"
                placeholder="Enter ownership"
                name="ownership"
                value={companyEditData.ownership}
                onChange={handleChange}
                md={6}
              /> */}
            </Row>

            {/* <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="BN Number/RC Number"
                placeholder="Enter BN Number/RC Number"
                name="bnNumber"
                value={companyEditData.bnNumber}
                onChange={handleChange}
                md={6}
              />

              <CustomInput
                type="text"
                label="Tax identification number"
                placeholder="Tax identification number"
                name="tin"
                value={companyEditData.tin}
                onChange={handleChange}
                md={6}
              />
            </Row> */}

            <div className="mt-5 text-white fw-bold">Address</div>
            <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="Street"
                placeholder="Enter street"
                name="street"
                value={companyEditData?.address?.street}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    address: { ...companyEditData.address, street: target.value },
                  })
                }
                md={6}
              />

              <CustomInput
                type="text"
                label="City"
                placeholder="Enter city"
                name="city"
                value={companyEditData?.address?.city}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    address: { ...companyEditData.address, city: target.value },
                  })
                }
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomSelect
                placeholder="Select state"
                type="text"
                name="state"
                label="State"
                md={6}
                onChange={(val) =>
                  setCompanyData({
                    ...companyEditData,
                    address: { ...companyEditData.address, state: val },
                  })
                }
                value={companyEditData?.address?.state}
                options={states}
              />

              <CustomSelect
                placeholder="Select country"
                type="text"
                name="country"
                label="Country"
                md={6}
                onChange={(val) =>
                  setCompanyData({
                    ...companyEditData,
                    address: { ...companyEditData.address, country: val },
                  })
                }
                value={companyEditData?.address?.country}
                options={getCountry}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Postal code"
                placeholder="Enter postal code"
                name="postalCode"
                value={companyEditData?.address?.postalCode}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    address: {
                      ...companyEditData.address,
                      postalCode: target.value,
                    },
                  })
                }
                md={6}
              />
            </Row>

            <div className="mt-5 text-white fw-bold">Registered Address</div>
            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Street"
                placeholder="Enter street"
                name="street"
                value={companyEditData?.registeredAddress?.street}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    registeredAddress: {
                      ...companyEditData.registeredAddress,
                      street: target.value,
                    },
                  })
                }
                md={6}
              />

              <CustomInput
                type="text"
                label="City"
                placeholder="Enter enter"
                name="city"
                value={companyEditData?.registeredAddress?.city}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    registeredAddress: {
                      ...companyEditData.registeredAddress,
                      city: target.value,
                    },
                  })
                }
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomSelect
                placeholder="Select state"
                type="text"
                name="state"
                label="State"
                md={6}
                onChange={(val) =>
                  setCompanyData({
                    ...companyEditData,
                    registeredAddress: {
                      ...companyEditData.registeredAddress,
                      state: val,
                    },
                  })
                }
                value={companyEditData?.registeredAddress?.state}
                options={states}
              />

              <CustomSelect
                placeholder="Select country"
                type="text"
                name="country"
                label="Country"
                md={6}
                onChange={(val) =>
                  setCompanyData({
                    ...companyEditData,
                    registeredAddress: {
                      ...companyEditData.registeredAddress,
                      country: val,
                    },
                  })
                }
                value={companyEditData?.registeredAddress?.country}
                options={getCountry}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Postal code"
                placeholder="Enter postal code"
                name="postalCode"
                value={companyEditData?.registeredAddress?.regPostalCode}
                onChange={({ target }) =>
                  setCompanyData({
                    ...companyEditData,
                    registeredAddress: {
                      ...companyEditData.registeredAddress,
                      postalCode: target.value,
                    },
                  })
                }
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomSelect
                label="Status"
                name="status"
                onChange={(status) =>
                  setCompanyData({ ...companyEditData, status: status })
                }
                value={companyEditData.status}
                options={[
                  { value: 'Active', label: 'Active' },
                  { value: 'Inactive', label: 'Inactive' },
                ]}
                md={6}
              />

              <CustomSelect
                md={6}
                label="Size"
                name="size"
                onChange={(size) => setCompanyData({ ...companyEditData, size: size })}
                value={companyEditData.size}
                options={[
                  { value: 'micro', label: 'Micro (0-10 employees)' },
                  { value: 'medium', label: 'Medium (10-50 employees)' },
                  { value: 'small', label: 'Small (50-200 employees)' },
                  { value: 'large', label: 'Large (200+ employees' },
                ]}
              />
            </Row>

            <Row className="mt-5">
              <div className="information-wrapper">
                <Row>
                  <div style={{ marginTop: '20px' }}>
                    <CustomButton fullWidth className="mt-3" onClick={handleSubmit}>
                      Submit
                    </CustomButton>
                  </div>
                  <div className="ms-auto">
                    <CustomButton
                      withoutBg
                      fullWidth
                      className="mt-3"
                      onClick={handleCancel}
                    >
                      Cancel
                    </CustomButton>
                  </div>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CompanyOnboardingDetails;
