import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import Modal from 'components/UI/Modal';
import TransactionInfo from './TransactionInfo';
import { Col, Row } from 'react-bootstrap';
import FileUpload from 'components/UI/FileUpload';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import BadgeType from '../UI/Table/BadgeType';
import { useHistory } from 'react-router-dom';
import { getSingleTransaction } from 'redux/actions/TransactionsAction';

const TransactionModal = ({ selectTransaction, setSelectTransaction }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [transaction, setTransaction] = useState([])

  const {
    getSingleTransaction: { data: transactionData, loading },
  } = useSelector(({ transaction }) => transaction);

  

  useEffect(() => {
    if(selectTransaction) {
      dispatch(getSingleTransaction(selectTransaction.transactionData.code))
    }
  }, [selectTransaction])

  useEffect(() => {
    if(transactionData) {
      setTransaction(transactionData.data)
    }
    
  }, [transactionData])
  
  

  const handleClose = () => {
    setSelectTransaction(null);
  };
  const handleClick = () => {
    history.push(`/budgets/${selectTransaction?.budget?.code}/overview`);
  };
  if (!selectTransaction) return <div />;
  if (loading) return <Loading color="#D28B28" size={30} />;
  const budgetAmount = `${transaction?.budget?.currency}${(parseInt(transaction?.budget?.amount, 10)/100).toLocaleString()}`;
  return (
    <Modal show={selectTransaction} onClose={handleClose} >
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="card-title">Transaction details</h2>
              <BadgeType value={selectTransaction.status} />
            </div>
            {/* <TransactionInfo title="Company" value={selectTransaction?.company.name} /> */}
            {/* <TransactionInfo title="Paid to" value={selectTransaction?.paidto} /> */}
            <TransactionInfo title="Trxn Code" value={selectTransaction?.code} />
            <TransactionInfo title="Amount" value={selectTransaction?.amount} />
            <TransactionInfo title="Account Name" value={transaction?.bankAccount?.accountName} />
            <TransactionInfo title="Account Number" value={transaction?.bankAccount?.number} />
            <TransactionInfo title="Bank Name" value={transaction?.bankAccount?.bankName} />
            <TransactionInfo title="Budget name" value={transaction?.budget?.name} />
            <TransactionInfo title="Description" value={transaction?.description} />
            <TransactionInfo title="Payer Name" value={`${transaction?.payer?.firstName} ${transaction?.payer?.lastName}`} />
            <TransactionInfo title="Payer email" value={transaction?.payer?.email} />
            {selectTransaction?.status.value === "failed" && 
            <TransactionInfo title="Failure Reason" value={transaction?.failure_reason} />}
            {/* <TransactionInfo title="Paid by" value={selectTransaction?.by.value} /> */}
            {/* <TransactionInfo
              title="Budget"
              onTitleClick={handleClick}
              value={
                <div>
                  <h3>{selectTransaction?.budget.value} </h3>
                </div>
              }
              withIcon={false}
              fullCursor
              removeIconBg
              isCopy={false}
            /> */}
            {/* <TransactionInfo title="Transaction type" value={ selectTransaction?.card ? 'Card Payment' : 'Bank Payment' } />
            { selectTransaction?.card ? <Row>
              <Col col={6}>
                <TransactionInfo
                  title="Paid with"
                  value={selectTransaction?.card.value}
                />
              </Col>
              <Col col={6}>
                <TransactionInfo title="Payment Method" value={ selectTransaction?.card.type } />
              </Col>
            </Row> : <Row>
              <Col col={6}>
                <TransactionInfo
                    title="Bank Name"
                    // value={ selectTransaction?.bankAccount.bankName }
                    value='UBA'
                />
              </Col>
              <Col col={6}>accountNumber
                <TransactionInfo title="Account Number" value='2040994945' />
              </Col>
            </Row> }
            <TransactionInfo
              title="Transaction ID"
              // value={selectTransaction?.reference}
              value={'102345789EDEE'}
              withIcon={
                <>
                  Copy <CopyIcon />
                </>
              }
            />

            <FileUpload
              label="Add a receipt"
              placeholder="Upload file"
              wrapperClass="mt-4 mb-3"
            /> */}
            {/* <CustomButton fullWidth className="mt-3">
              Save
            </CustomButton> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
