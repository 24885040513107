import React, { useEffect, useState } from 'react';
import { Accordion, Row, Col, Container } from 'react-bootstrap';
import AccordionCard from 'components/UI/AccordionCard';
import CustomInput from 'components/UI/CustomInput';
import CustomButton from 'components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout, update_user_profile } from '../../redux/actions/AuthAction';
import 'react-phone-number-input/style.css';
import {
  getInternationalFormat,
  validateNigeriaNumber,
  validatePassword,
} from '../../utils/helper';
import { useHistory } from 'react-router-dom';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import { isValidPhoneNumber } from 'libphonenumber-js';
import CustomSelect from 'components/UI/CustomSelect';

const OnboardingDetails = () => {
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;
  const dispatch = useDispatch();

  const {
    getSingleComplience: { data: complienceData, loading: loadingComplience },
  } = useSelector(({ Kybrequest }) => Kybrequest);

  console.log(complienceData);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    localFormat: '',
    internationalFormat: '',
    countryCode: '',
    email: '',
  });

  const [changePassword, setChangePassword] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const handleChangePersonalInfo = ({ target: { name, value } }) => {
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleSubmit = () => {
    if (!personalInfo.firstName)
      return toast(getSimplifiedError('Please enter your first name'), {
        type: 'error',
      });
    // if (!personalInfo.lastName)
    //   return toast(getSimplifiedError('Please enter your last name'), {
    //     type: 'error',
    //   });

    // if (
    //   !isValidPhoneNumber(personalInfo.internationalFormat) ||
    //   !validateNigeriaNumber(personalInfo.internationalFormat)
    // )
    //   return toast(getSimplifiedError('Please enter a valid phone number'), {
    //     type: 'error',
    //   });

    // if (!personalInfo.email)
    //   return toast(getSimplifiedError('Please enter a valid phone number'), {
    //     type: 'error',
    //   });
    // const code = personalInfo.countryCode?.substring(1);
    const payloadData = {
      firstName: personalInfo.firstName,
      middleName: personalInfo.middleName || null,
      lastName: personalInfo.lastName,
      phoneNumber: {
        countryCode: String(personalInfo.countryCode),
        localFormat: personalInfo.localFormat,
      },
    };
    dispatch(update_user_profile(payloadData));
  };

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const { firstName, lastName, middleName, email, phoneNumber = {} } = userData?.user;
      setPersonalInfo({
        ...personalInfo,
        firstName: firstName || '',
        middleName: middleName || '',
        lastName: lastName || '',
        localFormat: phoneNumber?.localFormat || '',
        countryCode: phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(phoneNumber?.countryCode, phoneNumber?.localFormat) ||
          '',
        email: email || '',
      });
    }
  }, [userData]);

  return (
    <div className="Profile-wrapper pt-1 pb-5">
      <Container>
        <Row className="mb-1">
          <Col md={12} className="form-normal">
            <div className="text-white fw-bold">Personal Information</div>
            <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="Name"
                placeholder="Enter name"
                name="name"
                value={personalInfo.name}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Website"
                placeholder="Enter website"
                name="website"
                value={personalInfo.website}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="Contact email"
                placeholder="Enter contact email"
                name="contactEmail"
                value={personalInfo.contactEmail}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomSelect
                placeholder="Select industry"
                type="text"
                name="industry"
                label="Industry"
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="Date of registration"
                placeholder="date of registration"
                name="dateOfRegistration"
                value={personalInfo.dateOfRegistration}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomSelect
                placeholder="Select business type"
                type="text"
                name="industry"
                label="Business type"
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="Description"
                placeholder="Enter description"
                name="description"
                value={personalInfo.description}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <div className="mt-5 text-white fw-bold">Director Info</div>
            <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name="firstName"
                value={personalInfo.firstName}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Last name"
                placeholder="Enter last name"
                name="lastName"
                value={personalInfo.lastName}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="BVN"
                placeholder="Enter BVN"
                name="bvn"
                value={personalInfo.bvn}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Ownership"
                placeholder="Enter ownership"
                name="ownership"
                value={personalInfo.ownership}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3 mt-3 align-items-center">
              <CustomInput
                type="text"
                label="BN Number/RC Number"
                placeholder="Enter BN Number/RC Number"
                name="bnNumber"
                value={personalInfo.bnNumber}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Tax identification number"
                placeholder="Tax identification number"
                name="tin"
                value={personalInfo.tin}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <div className="mt-5 text-white fw-bold">Address</div>
            <Row className="mb-3 align-items-center">
              <CustomInput
                type="text"
                label="Street"
                placeholder="Enter street"
                name="street"
                value={personalInfo.street}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="City"
                placeholder="Enter city"
                name="city"
                value={personalInfo.city}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="State"
                placeholder="Enter state"
                name="state"
                value={personalInfo.state}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Country"
                placeholder="Enter country"
                name="country"
                value={personalInfo.country}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Postal code"
                placeholder="Enter postal code"
                name="postalCode"
                value={personalInfo.postalCode}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <div className="mt-5 text-white fw-bold">Registered Address</div>
            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Street"
                placeholder="Enter street"
                name="regStreet"
                value={personalInfo.regStreet}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="City"
                placeholder="Enter enter"
                name="regCity"
                value={personalInfo.regCity}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="State"
                placeholder="Enter state"
                name="regState"
                value={personalInfo.regState}
                onChange={handleChangePersonalInfo}
                md={6}
              />

              <CustomInput
                type="text"
                label="Country"
                placeholder="Enter country"
                name="country"
                value={personalInfo.regCountry}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mb-3  align-items-center">
              <CustomInput
                type="text"
                label="Postal code"
                placeholder="Enter postal code"
                name="regPostalCode"
                value={personalInfo.regPostalCode}
                onChange={handleChangePersonalInfo}
                md={6}
              />
            </Row>

            <Row className="mt-5">
              <Col md={8}>
                <CustomButton onClick={handleSubmit} fullWidth={true}>
                  Save changes
                </CustomButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OnboardingDetails;
